import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LOGIN, DASHBOARD, CAMPAIGNS } from "settings/constants";
import AuthProvider from "context/auth";
import { InLineLoader } from "components/InlineLoader/InlineLoader";
import PrivateRoute from "./PrivateRoutes";
const Layout = lazy(() => import("layouts/Layout"));
const ClientAdminDashboard = lazy(() => import("views/ClientAdmin/Dashboard/ClientAdminDashboard"));
const NotFound = lazy(() => import("views/NotFound/NotFound"));
const Login = lazy(() => import("views/Login/Login"));
const Campaigns = lazy(() => import("views/ClientAdmin/Campaigns/CampaignsMain"));

const ClientAdminRoutes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InLineLoader />}>
                <Switch>
                    <PrivateRoute exact={true} path={DASHBOARD}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <ClientAdminDashboard />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={CAMPAIGNS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Campaigns />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <Route path={LOGIN}>
                        <Login />
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default ClientAdminRoutes;
