
import './theme/global.css';
import * as serviceWorker from './serviceWorker';
import AdminRoutes from './routes/routes.admin';
import ClientAdminRoutes from 'routes/routes.clientAdmin';
import ReactDOM from 'react-dom';
import { BaseProvider } from 'baseui';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { customTheme } from './theme';

const engine = new Styletron();


const AdminApp = () => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={customTheme}>
        <BrowserRouter>
          <AdminRoutes />
        </BrowserRouter>
      </BaseProvider>
    </StyletronProvider>
  );
}
const ClientAdminApp = () => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={customTheme}>
        <BrowserRouter>
          <ClientAdminRoutes />
        </BrowserRouter>
      </BaseProvider>
    </StyletronProvider>
  );
}


const appElement = document.getElementById("root");
if (process.env.REACT_APP_BUILD_TARGET === 'admin'){
        ReactDOM.render(<AdminApp />, appElement);
} else {
        ReactDOM.render(<ClientAdminApp />, appElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
