import { BookingProviderType } from "models/BookingSettingModel";

export const getProviderName = (providerType: BookingProviderType): string => {
    switch (providerType) {
        case 1:
            return "Easy cashier";
        case 2:
            return "Its perfect";
        case 5:
            return "Valei";
        default:
            return "Service not found";
    }
}

export const getProviderPath = (providerType: BookingProviderType): string => {
    switch (providerType) {
        case 1:
            return "easy-cashier";
        case 2:
            return "its-perfect";
        case 5:
            return "valei";
        default:
            return "Service not found";
    }
}
export const availableChannels = [
    {
        id: 'be5eb3fa-2bd7-49e2-aaad-7dd9a140f108',
        name: 'Book/Beauty',
    },
    {
        id: '24057310-d2b1-44f9-8dd2-565c25332a4e',
        name: 'Rapide',
    },
    {
        id: '7c22a05e-7894-4a52-ade7-08305d0bf814',
        name: 'Kicks',
    },
]
